import { MutationKey } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { DeviceID, IdentifyStatus } from '@playq/octopus2-devices';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const identifyDevice: MutationKey = ['identify', 'device'];

export const useIdentifyDevice = (
  options?: UseMutationOptionsExtended<IdentifyStatus, GenericFailure | Error, { device: DeviceID; data: string }>
) =>
  useEitherMutation(({ device, data }) => services2.onlineSubscriptionService.identify(device, data), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: identifyDevice,
    ...options,
  });
