import { createContext } from 'react';

import { services2, Services2 } from './services2';

export * from './services2';
export * from './hooks';
export * from './transport';
export * from './service-hooks';

export interface IServicesContextProps {
  services2: Services2;
}

export const ServicesContext = createContext<IServicesContextProps>({
  services2,
});
