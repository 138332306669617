import { UseMutationOptions } from '@tanstack/react-query';

import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';
import { MatchConfigID } from '@playq/services-rush';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

export type MatchPromotionPayload = {
  id: MatchConfigID;
  env: Environment;
  demote?: boolean;
};

export const useMatchesPromotion = (
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, MatchPromotionPayload>
) =>
  useEitherMutation(
    ({ id, env, demote }) =>
      demote ? services2.matchesService.demote(id, env) : services2.matchesService.promote(id, env),
    options
  );
