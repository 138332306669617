import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { TraitsResponse, DeviceID } from '@playq/octopus2-devices';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { deviceToolkit } from '/store';

export const traitsDevices: unknown[] = ['traits', 'devices'];
export const useGetTraits = (options?: UseQueryOptions<TraitsResponse | undefined, GenericFailure | Error>) => {
  const location = useLocation<{ refetch?: string }>();

  const subscribtionDevice = useSelector(deviceToolkit.selectors.subscribtionDevice);
  const { mutate, ...res } = useEitherQuery(
    traitsDevices.concat(subscribtionDevice?.device.id, location.state?.refetch),
    () => {
      return services2.onlineSubscriptionService.traits(new DeviceID(subscribtionDevice?.device.id));
    },
    {
      onError: (err) => snackbarService.genericFailure(err),
      staleTime: 0,
      cacheTime: 0,
      ...options,
    }
  );
  const traits = useMemo(() => res.data?.traits || [], [res.data?.traits]);
  return { ...res, traits };
};
