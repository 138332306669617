import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { SynapseProblemID } from '@playq/octopus2-ml';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { deleteProblemMutationKey } from './constants';

export const useProblemDelete = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, SynapseProblemID>
) =>
  useEitherMutation((id) => services2.synapseProblemsService.delete(id), {
    mutationKey: deleteProblemMutationKey,
    ...options,
  });
