import { AppTeamTemplateId, CommonSuccess, GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { deleteTeamMutationKey } from './constants';

export const useTeamDelete = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, AppTeamTemplateId>
) =>
  useEitherMutation((id) => services2.teamsService.delete(id), {
    mutationKey: deleteTeamMutationKey,
    ...options,
  });
