import { UseMutationOptions } from '@tanstack/react-query';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { DeleteSentMessagesPayload } from './types';

export const useDeleteAllSentMessages = (
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, DeleteSentMessagesPayload>
) =>
  useEitherMutation(
    ({ appID, messageSender, env }) =>
      services2.teamsChatLookupService.deleteAllSentMessages(appID, messageSender, env),
    {
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );
