import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { UserID } from '@playq/octopus-common';
import { UserPublic } from '@playq/octopus2-auth';

import { services2 } from '/api/services2';
import { createRequestQueue, unwrapEither } from '/api/service-hooks';

export const usersServicePublicTags: string[] = ['users', 'service', 'public'];

const queue = createRequestQueue<UserID, UserPublic>(
  (userIds) => unwrapEither(services2.usersService.retrievePublicFor(userIds)),
  {
    predicate: (userId, user) => userId.serialize() === user?.id.serialize(),
  }
);

export const useUserPublic = (id: UserID, options?: UseQueryOptions<UserPublic | undefined, GenericFailure | Error>) =>
  useQuery<UserPublic | undefined, GenericFailure | Error>(
    usersServicePublicTags.concat(id.serialize()),
    () => queue.push(id),
    {
      staleTime: Infinity,
      keepPreviousData: true,
      ...options,
    }
  );
