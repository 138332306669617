import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { Space } from '@playq/octopus2-apps';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { updateSpaceMutationKey } from './constants';

export const useSpaceUpdate = (options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, Space>) =>
  useEitherMutation((space) => services2.spacesService.update(space), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: updateSpaceMutationKey,
    ...options,
  });
