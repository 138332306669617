import { QueryKey, MutationKey } from '@tanstack/react-query';

import { services2 } from '/api/services2';

export const teamsService = services2.teamsService;
export const teamsServiceName = teamsService.getClassName();

// the same names as the names of the methods in /common/scripts/definitions/script-hooks.d.ts
export const TEAM_SAVE_HOOK_NAME = 'onTeamSave';
export const TEAM_PROMOTE_HOOK_NAME = 'onTeamPromote';
export const TEAM_DEMOTE_HOOK_NAME = 'onTeamDemote';

export const teamsQueryKeys: QueryKey = ['teams', 'query'];
export const teamGetKeys: QueryKey = ['team', 'get'];

export const createTeamMutationKey: MutationKey = ['team', 'social', 'mutation', 'create'];
export const updateTeamMutationKey: MutationKey = ['team', 'social', 'mutation', 'update'];
export const deleteTeamMutationKey: MutationKey = ['team', 'social', 'mutation', 'delete'];
