import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { ScriptResponse, ScriptID, ScriptCreateData } from '@playq/octopus2-scripts';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { updateScriptMutationKey } from './constants';

export const useScriptUpdate = (
  options?: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    {
      id: ScriptID;
      data: ScriptCreateData;
      version?: number;
    },
    ScriptResponse | undefined
  >
) => {
  return useEitherMutation(({ id, data, version }) => services2.scriptsService.update(id, data, version), {
    mutationKey: updateScriptMutationKey,
    ...options,
  });
};
