import { ScriptID } from '@playq/octopus2-scripts';
import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { deleteScriptMutationKey } from './constants';

export const useScriptDelete = (
  options: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, ScriptID>
) => {
  return useEitherMutation((id) => services2.scriptsService.delete(id), {
    mutationKey: deleteScriptMutationKey,
    ...options,
  });
};
