import { useSelector } from 'react-redux';
import { UseQueryOptions } from '@tanstack/react-query';

import { UserAnnotations } from '@playq/octopus-notifications';
import { GenericFailure } from '@playq/services-shared';

import { useEitherQuery } from '/api/service-hooks';
import { appToolkit } from '/store';

import { TimelineService } from './types';

export const useGetEntityAnnotations = (
  getAnnotations: NonNullable<TimelineService['getAnnotations']>,
  serviceClassName: string,
  options?: UseQueryOptions<UserAnnotations | undefined, GenericFailure | Error>
) => {
  const appID = useSelector(appToolkit.selectors.appID);
  return useEitherQuery(
    [serviceClassName, 'getAnnotations'],
    () => {
      if (appID) {
        return getAnnotations(appID);
      }
    },
    {
      enabled: !!appID,
      keepPreviousData: true,
      ...options,
    }
  );
};
