import { QueryKey, MutationKey } from '@tanstack/react-query';

export const spacesServiceQueryKeys: QueryKey = ['spaces', 'query'];

export const createSpaceMutationKey: MutationKey = ['space', 'mutation', 'create'];
export const updateSpaceMutationKey: MutationKey = ['space', 'mutation', 'update'];
export const deleteSpaceMutationKey: MutationKey = ['space', 'mutation', 'delete'];

export const spacePromotionsKey = ['space', 'promotions'] as const;

export const spacePromotionsQueryKey = ['space', 'promotions', 'query'] as const;

export const spacePromotionsCopyKey = ['space', 'promotions', 'mutate', 'copy'] as const;
export const spacePromotionsCloneKey = ['space', 'promotions', 'mutate', 'clone'] as const;
export const spacePromotionsCleanKey = ['space', 'promotions', 'mutate', 'clear'] as const;
export const spacePromotionsUpdateKey = ['space', 'promotions', 'mutate', 'update'] as const;

export const spacePromotionsMutationKeys = [
  spacePromotionsCopyKey,
  spacePromotionsCloneKey,
  spacePromotionsCleanKey,
  spacePromotionsUpdateKey,
] as const;
