import { useSelector } from 'react-redux';
import { MutationKey } from '@tanstack/react-query';

import { DeviceID } from '@playq/octopus2-devices';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { deviceToolkit } from '/store';

export const downloadFiles: MutationKey = ['files', 'download'];

export const useDownloadFiles = (
  options?: UseMutationOptionsExtended<
    string,
    GenericFailure | Error,
    {
      path: string;
      preview: boolean;
    }
  >
) => {
  const sub = useSelector(deviceToolkit.selectors.subscribtionDevice);
  return useEitherMutation(
    ({ path, preview }) =>
      services2.onlineSubscriptionService.downloadFile(new DeviceID(sub?.device.id), path, preview),
    {
      onError: (err) => snackbarService.genericFailure(err),
      mutationKey: downloadFiles,
      ...options,
    }
  );
};
