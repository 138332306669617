import { UseQueryOptions } from '@tanstack/react-query';

import { AppID, Filter } from '@playq/octopus-common';
import { NotificationsResponse, NotificationsSort } from '@playq/octopus-notifications';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';

import { useEitherQuery } from '/api/service-hooks';
import type { TimelineService } from '/common/AppTimeline/TimelineModules/types';

export const useQueryTimeline = (
  service: TimelineService,
  appID: AppID | undefined,
  iterator: OffsetLimit,
  sortBy: NotificationsSort[],
  filterBy: {
    [key: string]: Filter;
  },
  options: UseQueryOptions<NotificationsResponse | undefined, GenericFailure | Error> = {}
) => {
  return useEitherQuery(
    [service.getFullClassName(), 'queryNotifications', appID, iterator, sortBy, filterBy],
    () => {
      if (appID) {
        return service.queryNotifications(appID, iterator, sortBy, filterBy);
      }
    },
    {
      enabled: !!appID,
      keepPreviousData: true,
      ...options,
    }
  );
};
