import { UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { AppID, Environment } from '@playq/octopus-common';
import { GenericFailure } from '@playq/services-shared';
import { TeamIdWithTemplateId, AvailableChannels } from '@playq/services-teams';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';

export const teaamsChatChannelKeys: unknown[] = [
  'service',
  'lookup',
  'teams',
  'chats',
  'channels',
  'availableChannels',
];
const emptyArray: [] = [];

export const useAvailableChannels = (
  teamId: TeamIdWithTemplateId | undefined,
  env: Environment | undefined,
  options?: UseQueryOptions<AvailableChannels | undefined, GenericFailure | Error>
) => {
  const appID: AppID | undefined = useSelector(appToolkit.selectors.appID);

  const res = useEitherQuery(
    teaamsChatChannelKeys.concat(appID, teamId, env),
    () => {
      if (
        appID !== undefined &&
        teamId !== undefined &&
        env !== undefined &&
        teamId.teamId !== undefined &&
        teamId.teamTemplateId !== undefined
      ) {
        return services2.teamsChatLookupService.availableChannels(appID, teamId, env);
      }
    },
    {
      keepPreviousData: false,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  return {
    ...res,
    channels: res.data?.channels || emptyArray,
  };
};
