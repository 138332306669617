import { UseMutationOptions } from '@tanstack/react-query';

import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { ILadderPromotionPayload, ILadderPromotionOptionsVariables } from './types';
import { laddersService, laddersServiceName } from './constants';

export const useLeaderboardPromotionScripted = (
  scriptsResolver: IInvokeScriptsHook,
  demote: boolean,
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, ILadderPromotionOptionsVariables>
) =>
  useEitherMutationScripted<ILadderPromotionPayload, CommonSuccess, IInvokeScriptsHook>({
    serviceMethod: demote
      ? laddersService.unregisterLadder.bind(laddersService)
      : laddersService.registerLadder.bind(laddersService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: laddersServiceName,
      mutationType: `leaderboard ${demote ? 'demote' : 'promote'}`,
    },
    options: {
      onError: onResponseError,
      ...options,
    },
  });
