import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { PromotionFeatures } from '@playq/octopus-common';

import { useEitherQuery } from '/api/service-hooks';

import { IPromotionsResponse, IPromotionServiceWithBatchQuery } from './types';

export const queryBulkPromotionsKeys: unknown[] = ['bulk-promotions', 'query'];
export const useQueryBulkPromotions = <ID>(
  ids: ID[] | undefined,
  features: PromotionFeatures[],
  service: IPromotionServiceWithBatchQuery<ID>,
  options?: UseQueryOptions<IPromotionsResponse | undefined, GenericFailure | Error>
) =>
  useEitherQuery(
    queryBulkPromotionsKeys.concat(ids, features),
    () => {
      if (ids) {
        return service.queryPromotionsBatch(ids, features);
      }
    },
    {
      enabled: !!ids && ids.length > 0,
      cacheTime: 0,
      ...options,
    }
  );
