import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { AppLadderId } from '@playq/services-clerk';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { deleteLeaderboardMutationKey } from './constants';

export const useLeaderboardDelete = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, AppLadderId>
) =>
  useEitherMutation((id) => services2.lboardsService.deleteLadder(id), {
    mutationKey: deleteLeaderboardMutationKey,
    ...options,
  });
