import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { Filter, SpaceID, TextFilter } from '@playq/octopus-common';
import { Space, SpacesFilterField } from '@playq/octopus2-apps';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';

import { createRequestQueue } from '/api/service-hooks';
import { services2 } from '/api/services2';

const delay = 50;

const makeFilterBy = (spacesID: (SpaceID | undefined)[]) => {
  const filterBy: Record<string, Filter> = {};
  const currentId = spacesID[spacesID.length - 1];
  const text = currentId?.id;
  if (!text) {
    return filterBy;
  }
  const filter = new TextFilter({ text });
  filterBy[SpacesFilterField.ID] = filter;
  return filterBy;
};

const queue = createRequestQueue<SpaceID | undefined, Space>(
  (spacesID) => {
    const filterBy = makeFilterBy(spacesID);
    return new Promise((resolve, reject) => {
      services2.spacesService
        .querySpaces(new OffsetLimit({ offset: 0, limit: spacesID.length }), [], filterBy)
        .then((data) => data.bifold((res) => resolve(res.entities), reject));
    });
  },
  {
    predicate: (spaceID, space) => space?.id.serialize() === spaceID?.serialize(),
    timeout: delay,
  }
);

export const spacesQueryTags: unknown[] = ['spaces', 'service', 'query-single', 'batch'];

export const useSpaceQuery = (id?: SpaceID, options?: UseQueryOptions<Space | undefined, GenericFailure | Error>) => {
  return useQuery<Space | undefined, GenericFailure | Error>(spacesQueryTags.concat(id), () => queue.push(id), {
    ...options,
    enabled: id !== undefined && (options?.enabled ?? true),
  });
};
