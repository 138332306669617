import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { LadderTemplate } from '@playq/services-clerk';

import { UseMutationOptionsExtended } from '/api/service-hooks';
import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { ILadderUpdateOptionsVariables, IUpdateLadderParams } from './types';
import { laddersServiceName, laddersService, updateLeaderboardMutationKey } from './constants';

export const useLeaderboardUpdateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    ILadderUpdateOptionsVariables,
    LadderTemplate | undefined
  >
) =>
  useEitherMutationScripted<IUpdateLadderParams, CommonSuccess, IInvokeScriptsHook, LadderTemplate | undefined>({
    serviceMethod: laddersService.updateLadder.bind(laddersService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: laddersServiceName,
      mutationType: 'leaderboard update',
    },
    options: {
      mutationKey: updateLeaderboardMutationKey,
      onError: onResponseError,
      ...options,
    },
  });
