import { QuerySynapseProblemsResponse, SynapseProblemID } from '@playq/octopus2-ml';

import { filterById } from '/helpers';
import { MutateCustomFieldsArgs } from '/api/service-hooks';

export const createNewProblemsResponseAfterProblemDeleting = (
  prevResponse: QuerySynapseProblemsResponse,
  removedEntityID?: SynapseProblemID | SynapseProblemID[]
) => {
  const newResponse = new QuerySynapseProblemsResponse(prevResponse.serialize());
  if (removedEntityID !== undefined) {
    newResponse.features = prevResponse.features.filter(filterById(removedEntityID));
  }
  return newResponse;
};

export const mutateEnvsAndFeaturesAfterProblemDeleting = ({
  response,
  nextResponse,
  id,
}: MutateCustomFieldsArgs<QuerySynapseProblemsResponse, SynapseProblemID>) => {
  if (!id || !nextResponse) {
    return;
  }
  const nextProblemFeatures = nextResponse.features.find(filterById(id, true));
  if (nextProblemFeatures !== undefined) {
    response.features.push(nextProblemFeatures);
  }
  const nextProblemPromotions = nextResponse.promotions.find(filterById(id, true));
  if (nextProblemPromotions !== undefined) {
    response.promotions.push(nextProblemPromotions);
  }
};
