import { UseMutationOptions } from '@tanstack/react-query';

import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { AppID, Environment } from '@playq/octopus-common';

import { snackbarService } from '/common/snackbarService';
import { ServicesOptions, ServiceConfig, getServiceMethods } from '/component/Services/Config/ServicesHelper';
import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks/useEitherMutation';

export const useSetServicesConfig = (
  service: ServicesOptions,
  options?: UseMutationOptions<
    CommonSuccess,
    GenericFailure | Error,
    { appID: AppID; env: Environment; config: ServiceConfig }
  >
) => {
  const methods = getServiceMethods(services2, service);

  return useEitherMutation(
    // @ts-expect-error config always has the correct instance
    ({ appID, env, config }) => methods.setConfig$(appID, env, config),
    {
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );
};
