import { MutationKey } from '@tanstack/react-query';

import { DeviceID } from '@playq/octopus2-devices';
import { Formula } from '@playq/octopus-common';
import { Trait } from '@playq/services-common-traits';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const formulaDevices: MutationKey = ['formula', 'devices'];

export const useFormula = (
  options?: UseMutationOptionsExtended<
    Trait | undefined,
    GenericFailure | Error,
    { device: DeviceID; formula: Formula }
  >
) =>
  useEitherMutation(({ device, formula }) => services2.onlineSubscriptionService.formula(device, formula), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: formulaDevices,
    ...options,
  });
