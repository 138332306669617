import { MatchesResponse } from '@playq/octopus2-social';
import { MatchConfigID } from '@playq/services-rush';

import { MutateCustomFieldsArgs } from '/api/service-hooks';

const removeItemFromPromotionIfPromoted = (id: MatchConfigID, promotions: MatchConfigID[]): boolean => {
  const removedMatchID = promotions.splice(
    promotions.findIndex((matchID) => matchID.serialize() === id.serialize()),
    1
  );
  return removedMatchID.length > 0;
};

export const mutateEnvsAfterMatchDeleting = ({
  response,
  nextResponse,
  id,
}: MutateCustomFieldsArgs<MatchesResponse, MatchConfigID>) => {
  if (!id || !nextResponse) {
    return;
  }
  if (removeItemFromPromotionIfPromoted(id, nextResponse.envs.sandbox)) {
    response.envs.sandbox.push(id);
  }
  if (removeItemFromPromotionIfPromoted(id, nextResponse.envs.live)) {
    response.envs.live.push(id);
  }
};
