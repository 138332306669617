import { TeamTemplatesResponse } from '@playq/octopus2-social';
import { AppTeamTemplateId } from '@playq/services-shared';

import { MutateCustomFieldsArgs } from '/api/service-hooks';

const removeItemFromPromotionIfPromoted = (id: AppTeamTemplateId, promotions: AppTeamTemplateId[]): boolean => {
  const removedTeamID = promotions.splice(
    promotions.findIndex((teamID) => teamID.serialize() === id.serialize()),
    1
  );
  return removedTeamID.length > 0;
};

export const mutateEnvsAndGameCurrencyAfterTeamDeleting = ({
  response,
  nextResponse,
  id,
}: MutateCustomFieldsArgs<TeamTemplatesResponse, AppTeamTemplateId>) => {
  if (!id || !nextResponse) {
    return;
  }
  response.gameCurrency = { ...response.gameCurrency, ...nextResponse.gameCurrency };
  if (removeItemFromPromotionIfPromoted(id, nextResponse.envs.sandbox)) {
    response.envs.sandbox.push(id);
  }
  if (removeItemFromPromotionIfPromoted(id, nextResponse.envs.live)) {
    response.envs.live.push(id);
  }
};
