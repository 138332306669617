import { MutationKey } from '@tanstack/react-query';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { DeviceID } from '@playq/octopus2-devices';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const deleteDevice: MutationKey = ['delete', 'device'];

export const useDeleteDevice = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, { device: DeviceID }>
) =>
  useEitherMutation(({ device }) => services2.onlineDeviceService.deleteDevice(device), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: deleteDevice,
    ...options,
  });
