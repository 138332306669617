import { UseMutationOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { TeamMemberVault } from '@playq/services-teams';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { UpdateTeamMemberValtPayload } from './types';

export const useUpdateMemberVault = (
  options?: UseMutationOptions<TeamMemberVault, GenericFailure | Error, UpdateTeamMemberValtPayload>
) =>
  useEitherMutation(
    ({ appID, teamId, userId, update, expectedVersion, env }) =>
      services2.teamsLookupService.updateMemberVault(appID, teamId, userId, update, expectedVersion, env),
    {
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );
