import { UseQueryOptions } from '@tanstack/react-query';

import { QueryScriptRuntimeResponse } from '@playq/octopus2-scripts';
import { GenericFailure } from '@playq/services-shared';
import { AppID } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

export const scriptsQueryRuntimeKeys: unknown[] = ['scripts', 'service', 'queryRuntime'];

export const useScriptsRuntimeQuery = (
  app: AppID,
  options?: UseQueryOptions<QueryScriptRuntimeResponse | undefined, GenericFailure | Error>
) => {
  const { mutate, ...res } = useEitherQuery(
    scriptsQueryRuntimeKeys.concat(app?.toString()),
    () => services2.scriptsService.queryScriptsRuntime(app),
    { ...options, enabled: options?.enabled !== false && app instanceof AppID }
  );

  return {
    ...res,
  };
};
