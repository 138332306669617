import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { CredentialsID } from '@playq/octopus2-auth';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

const emptyArray: [] = [];

export const jiraIntegrationServiceKeys: unknown[] = ['service', 'jira', 'integration'];

export const useGetIssueTypes = (
  credentialsID: CredentialsID | undefined,
  options?: UseQueryOptions<string[] | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => jiraIntegrationServiceKeys.concat(credentialsID), [credentialsID]);

  const res = useEitherQuery(
    keys,
    () => {
      if (credentialsID) {
        return services2.jiraIntegrationService.getIssueTypes(credentialsID);
      }
    },
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  return {
    ...res,
    issueTypes: res.data || emptyArray,
  };
};
