import { UseMutationOptions } from '@tanstack/react-query';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';

import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService';
import { IMutationScriptedOptionsVariables } from '../scriptsService/types';

import { IPromotionService } from './types';

export const useBulkPromoteScripted = <ID>(
  scriptsResolver: IInvokeScriptsHook | undefined,
  service: IPromotionService<ID>,
  options?: UseMutationOptions<
    CommonSuccess,
    GenericFailure | Error,
    IMutationScriptedOptionsVariables<Parameters<typeof service.promoteBatch>, IInvokeScriptsHook | undefined>
  >
) =>
  useEitherMutationScripted<Parameters<typeof service.promoteBatch>, CommonSuccess, IInvokeScriptsHook | undefined>({
    serviceMethod: service.promoteBatch.bind(service),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: service.getClassName(),
      mutationType: 'batch promote',
    },
    options,
  });
