import { UseMutationOptions } from '@tanstack/react-query';

import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { teamsService, teamsServiceName } from './constants';
import { ITeamPromotionParams, ITeamPromotionOptionsVariables } from './types';

export const useTeamPromotionScripted = (
  scriptsResolver: IInvokeScriptsHook,
  demote: boolean,
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, ITeamPromotionOptionsVariables>
) =>
  useEitherMutationScripted<ITeamPromotionParams, CommonSuccess, IInvokeScriptsHook>({
    serviceMethod: demote ? teamsService.demote.bind(teamsService) : teamsService.promote.bind(teamsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: teamsServiceName,
      mutationType: `team ${demote ? 'demote' : 'promote'}`,
    },
    options: {
      onError: onResponseError,
      ...options,
    },
  });
