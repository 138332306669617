import { QuerySpacesResponse } from '@playq/octopus2-apps';
import { SpaceID } from '@playq/octopus-common';

import { MutateCustomFieldsArgs } from '/api/service-hooks';

export const createNewSpacesResponseAfterSpaceDeleting = (
  prevResponse: QuerySpacesResponse,
  removedEntityID?: SpaceID | SpaceID[]
) => {
  const ids = Array.isArray(removedEntityID) ? removedEntityID : [removedEntityID];
  const newResponse = new QuerySpacesResponse(prevResponse.serialize());

  if (removedEntityID !== undefined) {
    const newFeatures: QuerySpacesResponse['features'] = {};
    for (const spaceIDSerialized in prevResponse.features) {
      if (!ids.find((id) => spaceIDSerialized === id?.serialize())) {
        newFeatures[spaceIDSerialized] = prevResponse.features[spaceIDSerialized];
      }
    }
    newResponse.features = newFeatures;
  }

  return newResponse;
};

export const mutateFeaturesAfterSpaceDeleting = ({
  response,
  nextResponse,
  id,
}: MutateCustomFieldsArgs<QuerySpacesResponse, SpaceID>) => {
  if (!id || !nextResponse) {
    return;
  }
  const nextSpaceFeatures = nextResponse.features[id.serialize()];
  if (nextSpaceFeatures !== undefined) {
    response.features[id.serialize()] = nextSpaceFeatures;
  }
};
