import { GenericFailure } from '@playq/services-shared';
import { Script } from '@playq/octopus2-scripts';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { IScriptCreatePayload } from './types';
import { createScriptMutationKey } from './constants';

export const useScriptCreate = (
  options: UseMutationOptionsExtended<Script, GenericFailure | Error, IScriptCreatePayload>
) => {
  return useEitherMutation(({ appID, data }) => services2.scriptsService.create(appID, data), {
    mutationKey: createScriptMutationKey,
    ...options,
  });
};
