import { QueryKey, MutationKey } from '@tanstack/react-query';

import { GenericFailureCode } from '@playq/services-shared';

export const INERRUPTED_BY_SCRIPS_DIAGNOSTICS_MESSAGE = 'interrupted by the scripts';

export const GENERIC_FAILURE_INTERRUPTED_BY_SCRIPTS_BASE = {
  diagnostics: INERRUPTED_BY_SCRIPS_DIAGNOSTICS_MESSAGE,
  reserved: {},
  code: GenericFailureCode.ConditionNotMet,
};

export const scriptsQueryKeys: QueryKey = ['scripts', 'query'];
export const scriptsRetrieveKeys: QueryKey = ['scripts', 'service', 'retrieve'];

export const createScriptMutationKey: MutationKey = ['script', 'mutation', 'create'];
export const updateScriptMutationKey: MutationKey = ['script', 'mutation', 'update'];
export const deleteScriptMutationKey: MutationKey = ['script', 'mutation', 'delete'];
