import { UseMutationOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { TeamMemberData } from '@playq/services-teams';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { MemberPayloadType } from './types';

export const useDemoteMember = (
  options?: UseMutationOptions<TeamMemberData, GenericFailure | Error, MemberPayloadType>
) =>
  useEitherMutation(
    ({ appID, teamId, userId, env }) => services2.teamsLookupService.demoteMember(appID, teamId, userId, env),
    {
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );
