import { GenericFailure } from '@playq/services-shared';
import { AppID } from '@playq/octopus-common';
import { MatchConfigurationData } from '@playq/octopus2-social';
import { MatchConfigID } from '@playq/services-rush';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { createMatchMutationKey } from './constants';

export type CreateMatchPayload = {
  appID: AppID;
  data: MatchConfigurationData;
};

export const useMatchCreate = (
  options?: UseMutationOptionsExtended<MatchConfigID, GenericFailure | Error, CreateMatchPayload>
) =>
  useEitherMutation(({ appID, data }) => services2.matchesService.create(appID, data), {
    mutationKey: createMatchMutationKey,
    ...options,
  });
