import { UseMutationOptions } from '@tanstack/react-query';

import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { MemberPayloadType } from './types';

export const useKickMember = (options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, MemberPayloadType>) =>
  useEitherMutation(
    ({ appID, teamId, userId, env }) => services2.teamsLookupService.kickMember(appID, teamId, userId, env),
    {
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );
