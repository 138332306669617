import { GenericFailure } from '@playq/services-shared';
import { AppLadderId } from '@playq/services-clerk';

import { UseMutationOptionsExtended } from '/api/service-hooks';
import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { ICreateLadderParams, ILadderCreateOptionsVariables } from './types';
import { createLeaderboardMutationKey, laddersService, laddersServiceName } from './constants';

export const useLeaderboardCreateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<AppLadderId, GenericFailure | Error, ILadderCreateOptionsVariables>
) =>
  useEitherMutationScripted<ICreateLadderParams, AppLadderId, IInvokeScriptsHook>({
    serviceMethod: laddersService.createLadder.bind(laddersService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: laddersServiceName,
      mutationType: 'leaderboard creation',
    },
    options: {
      mutationKey: createLeaderboardMutationKey,
      onError: onResponseError,
      ...options,
    },
  });
