import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AppID, Environment } from '@playq/octopus-common';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { GetTemplatesResult } from '@playq/octopus2-tgservices';

import { services2 } from '/api/services2';
import { UseQueryOptionsExtended, useEitherQuery, useQueryPrefetch } from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { ITableQuery } from '/shared/Table';

const emptyArray: [] = [];
const defaultOffsetLimit = new OffsetLimit({ offset: 0, limit: 10 });

export const teamsSupportQueryKeys: unknown[] = ['teams', 'service', 'support', 'templates'];

export const useGetTemplates = (
  env: Environment,
  offsetLimit: OffsetLimit = defaultOffsetLimit,
  query?: ITableQuery<string>,
  options?: UseQueryOptionsExtended<GetTemplatesResult | undefined, GenericFailure | Error>
) => {
  const appID: AppID | undefined = useSelector(appToolkit.selectors.appID);

  const keys = useMemo(
    () => teamsSupportQueryKeys.concat(appID, offsetLimit, env, query),
    [appID, offsetLimit, env, query]
  );

  const isValid = useMemo(() => appID !== undefined, [appID]);
  const enabled = useMemo(() => (options?.enabled ?? true) && isValid, [options?.enabled, isValid]);
  const enablePrefetch = useMemo(
    () => (options?.enablePrefetch ?? true) && isValid,
    [options?.enablePrefetch, isValid]
  );

  const res = useEitherQuery(keys, () => services2.teamsLookupService.getTemplates(appID as AppID, offsetLimit, env), {
    keepPreviousData: true,
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
    enabled,
  });

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  useQueryPrefetch({
    keys,
    enabled: enablePrefetch,
    total,
    args: [appID as AppID, offsetLimit, env],
    serviceMethod: services2.teamsLookupService.getTemplates.bind(services2.teamsLookupService),
  });

  return {
    ...res,
    templates: res.data?.templates || emptyArray,
    total,
    queryKeys: keys,
  };
};
