import { MutationKey } from '@tanstack/query-core';

import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { SpaceID } from '@playq/octopus-common';
import { AppSpacePromotions } from '@playq/octopus2-apps-utility';

import { UseMutationOptionsExtended, useEitherMutation } from '/api/service-hooks';
import { services2, experimentsQueryKeys, flowQueryKeys, gameEventsQueryKeys } from '/api';
import { snackbarService } from '/common/snackbarService';

import { spacePromotionsUpdateKey } from './constants';

export type UseAppSpacePromotionsUpdateParams = {
  spaceID: SpaceID;
  promotions: AppSpacePromotions;
};

export const useAppSpacePromotionsUpdate = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, UseAppSpacePromotionsUpdateParams>
) => {
  return useEitherMutation(
    ({ spaceID, promotions }) => services2.spacesPromotionsService.updatePromotions(spaceID, promotions),
    {
      onSuccess: () => {
        snackbarService.success('All the entities were successfully promoted to selected space.');
      },
      onError: (err) => {
        snackbarService.genericFailure(err);
      },
      removeQueriesKeysArray: [experimentsQueryKeys, flowQueryKeys, gameEventsQueryKeys],
      mutationKey: spacePromotionsUpdateKey as MutationKey,
      ...options,
    }
  );
};
