import { UseMutationOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';

import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService';
import { IMutationScriptedOptionsVariables } from '../scriptsService/types';

import { IDemotionsResponse, IPromotionService } from './types';

export const useBulkDemoteScripted = <ID>(
  scriptsResolver: IInvokeScriptsHook | undefined,
  service: IPromotionService<ID>,
  options: UseMutationOptions<
    IDemotionsResponse,
    GenericFailure | Error,
    IMutationScriptedOptionsVariables<Parameters<typeof service.demoteBatch>, IInvokeScriptsHook | undefined>
  > = {}
) =>
  useEitherMutationScripted<Parameters<typeof service.demoteBatch>, IDemotionsResponse, IInvokeScriptsHook | undefined>(
    {
      serviceMethod: service.demoteBatch.bind(service),
      scriptsResolver,
      snackbarMessageMetadata: {
        serviceName: service.getClassName(),
        mutationType: 'batch demote',
      },
      options,
    }
  );
