import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { AppID, Filter } from '@playq/octopus-common';
import { QueryScriptResponse, ScriptSort, ScriptBrief, ScriptActivation } from '@playq/octopus2-scripts';

import { services2 } from '/api/services2';
import {
  UseQueryOptionsExtended,
  useEitherQuery,
  useQueryPrefetch,
  useMutateQueryDataAfterEntityDeleting,
} from '/api/service-hooks';

import { IScriptVersionsActivated } from './types';
import { scriptsQueryKeys } from './constants';
import {
  createNewScriptsResponseAfterScriptDeleting,
  mutateFeaturesAndActivationsAfterScriptDeleting,
} from './helpers';

export const useScriptsQuery = (
  appID: AppID | undefined,
  iterator: OffsetLimit,
  sortBy: ScriptSort[],
  filterBy: {
    [key: string]: Filter;
  },
  options?: UseQueryOptionsExtended<QueryScriptResponse | undefined, GenericFailure | Error>
) => {
  const queryClient = useQueryClient();

  const keys = useMemo(
    () => scriptsQueryKeys.concat(appID, iterator, sortBy, filterBy),
    [appID, filterBy, iterator, sortBy]
  );

  const isValid = useMemo(() => appID !== undefined, [appID]);
  const enabled = useMemo(() => (options?.enabled ?? true) && isValid, [options?.enabled, isValid]);
  const enablePrefetch = useMemo(
    () => (options?.enablePrefetch ?? true) && isValid,
    [options?.enablePrefetch, isValid]
  );

  const {
    mutate,
    data: responseData,
    refetch,
    ...res
  } = useEitherQuery(keys, () => services2.scriptsService.queryScripts(appID as AppID, iterator, sortBy, filterBy), {
    keepPreviousData: true,
    ...options,
    enabled,
  });

  const activations = useMemo<IScriptVersionsActivated>(
    () =>
      responseData
        ? new Map(
            responseData.activations.map(
              ({ id: scriptID, active: scriptVersionActive }: ScriptActivation) =>
                [scriptID.id, scriptVersionActive ?? null] as [number, number | null]
            )
          )
        : (new Map() as IScriptVersionsActivated),
    [responseData]
  );

  const total = useMemo(() => responseData?.total ?? 0, [responseData?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: enablePrefetch,
    total,
    args: [appID as AppID, iterator, sortBy, filterBy],
    serviceMethod: services2.scriptsService.queryScripts.bind(services2.scriptsService),
  });

  const mutateDeletedItem = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator,
    mutate,
    refetch,
    getID: (s?: ScriptBrief) => s?.id,
    getEntities: (r: QueryScriptResponse) => r.scripts,
    setEntities: (r: QueryScriptResponse, newEntities: ScriptBrief[]) => {
      r.scripts = newEntities;
    },
    createNewResponse: createNewScriptsResponseAfterScriptDeleting,
    mutateCustomFields: mutateFeaturesAndActivationsAfterScriptDeleting,
  });

  const removeQueries = useCallback(() => {
    queryClient.removeQueries(scriptsQueryKeys);
  }, [queryClient]);

  return {
    ...res,
    scripts: responseData?.scripts ?? [],
    activations,
    total,
    tags: keys,
    mutateDeletedItem,
    removeQueries,
    refetch,
  };
};
