import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { SpaceID } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { deleteSpaceMutationKey } from './constants';

export const useSpaceDelete = (options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, SpaceID>) =>
  useEitherMutation((spaceID) => services2.spacesService.delete(spaceID), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: deleteSpaceMutationKey,
    ...options,
  });
