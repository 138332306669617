import { UseMutationOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { AppEntityVersion } from '@playq/octopus2-apps';

import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { segmentationService, segmentationServiceName } from './constants';
import { IUpsertSegmentationParams, ISegmentationUpsertOptionsVariables } from './types';

export const useSegmentationUpsertScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptions<AppEntityVersion, GenericFailure | Error, ISegmentationUpsertOptionsVariables>
) =>
  useEitherMutationScripted<IUpsertSegmentationParams, AppEntityVersion, IInvokeScriptsHook>({
    serviceMethod: segmentationService.upsert.bind(segmentationService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: segmentationServiceName,
      mutationType: 'segmentation upsert',
    },
    options: {
      onError: onResponseError,
      ...options,
    },
  });
