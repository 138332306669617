import { UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { AppID, Environment } from '@playq/octopus-common';
import { GenericFailure, TimeOffsetIterator } from '@playq/services-shared';
import { MessagePage, MessageReceiver } from '@playq/services-teams';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';

export const readMessagesKeys: unknown[] = ['service', 'support', 'teams', 'chat', 'readMessages'];
const emptyArray: [] = [];

export const useReadMessages = (
  receiver: MessageReceiver,
  timeOffsetIterator: TimeOffsetIterator,
  env: Environment,
  options?: UseQueryOptions<MessagePage | undefined, GenericFailure | Error>
) => {
  const appID: AppID | undefined = useSelector(appToolkit.selectors.appID);

  const keys = useMemo(
    () => readMessagesKeys.concat(appID, receiver, timeOffsetIterator, env, receiver.channel.getFullClassName()),
    [appID, receiver, timeOffsetIterator, env]
  );

  const res = useEitherQuery(
    keys,
    () => {
      if (!appID) {
        return undefined;
      }
      return services2.teamsChatLookupService.readMessages(appID, receiver, timeOffsetIterator, env);
    },
    {
      keepPreviousData: false,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );
  return { ...res, messages: res.data?.messages || emptyArray, pinned: res.data?.pinned || emptyArray };
};
