import { UseMutationResult } from '@tanstack/react-query';

import { Left } from '@playq/irt';
import { GenericFailure } from '@playq/services-shared';

import { useEitherMutation } from '/api/service-hooks';
import { IInvokeScriptsHookParams } from '/common/scripts/types';

import { IMutationScriptedOptionsVariables, IUseEitherMutationScriptedParams } from './types';
import { GENERIC_FAILURE_INTERRUPTED_BY_SCRIPTS_BASE } from './constants';

/**
 * @template S - a successful response type.
 * @param serviceName - string representing the name of the service
 * @param mutationType - string explaining the operation which was failed
 * @returns
 */
export const handleScriptsResolverError = <S>(serviceName: string, mutationType: string) =>
  Promise.resolve(
    new Left<GenericFailure, S>(
      new GenericFailure({
        message: `${serviceName}: ${mutationType} is interrupted by the scripts`,
        ...GENERIC_FAILURE_INTERRUPTED_BY_SCRIPTS_BASE,
      })
    )
  );

/**
 * A hook patching the default useEitherMutation behavior with a
 * scripts resolver logic allowing or disallowing to run the mutation.
 * @template T - an array type of a target service method params.
 * @template S - a successful response type.
 * @template H - the type of a scripts resolver.
 * @template C -  the react-query mutation context.
 * @param serviceMethod - the target service method supposed to be invoked for a mutation.
 * @param scriptsResolver - a resolver checking whether mutation should or should not be interrupted by the scripts.
 * @param snackbarMessageMetadata - the name of a service and mutation type to show in snackbar messages.
 * @param options - the react-query mutation options.
 * @returns the useEitherMutation result including the "mutate" function to run a mutation.
 */
export const useEitherMutationScripted = <T extends unknown[], S, H, C = unknown>({
  serviceMethod,
  scriptsResolver,
  snackbarMessageMetadata: { serviceName, mutationType },
  options,
}: IUseEitherMutationScriptedParams<T, S, H, C>): UseMutationResult<
  S,
  GenericFailure | Error,
  IMutationScriptedOptionsVariables<T, H>
> =>
  useEitherMutation(
    async ({
      serviceMethodArgs,
      scriptsResolverArgs,
      onMutationAllowance,
      onMutationInterruption,
    }: IMutationScriptedOptionsVariables<T, H>) => {
      if (!scriptsResolver) {
        return serviceMethod(...serviceMethodArgs);
      }

      const isMutationAllowed = Boolean(await scriptsResolver(...(scriptsResolverArgs as IInvokeScriptsHookParams)));

      if (isMutationAllowed) {
        onMutationAllowance?.();

        return serviceMethod(...serviceMethodArgs);
      }

      onMutationInterruption?.();

      return handleScriptsResolverError(serviceName, mutationType);
    },
    options
  );
