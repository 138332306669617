import { GenericFailure } from '@playq/services-shared';
import { SpaceContent, Space } from '@playq/octopus2-apps';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { createSpaceMutationKey } from './constants';

export const useSpaceCreate = <C>(
  options?: UseMutationOptionsExtended<Space | C, Error | GenericFailure, SpaceContent>
) =>
  useEitherMutation((content) => services2.spacesService.create(content), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: createSpaceMutationKey,
    ...options,
  });
