import { UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { AppID, Environment } from '@playq/octopus-common';
import { GenericFailure, TeamTemplateId } from '@playq/services-shared';
import { TeamTemplate } from '@playq/services-teams';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';

export const templateRetrieveSupportQueryKeys: unknown[] = ['teams', 'service', 'support', 'template', 'single'];

export const useGetTemplate = (
  templateId: TeamTemplateId,
  env: Environment,
  options?: UseQueryOptions<TeamTemplate | undefined, GenericFailure | Error>
) => {
  const appID: AppID | undefined = useSelector(appToolkit.selectors.appID);

  return useEitherQuery(
    templateRetrieveSupportQueryKeys.concat(appID, templateId, env),
    () => {
      if (appID) {
        return services2.teamsLookupService.getTemplate(appID, templateId, env);
      }
    },
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );
};
