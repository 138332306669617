import { useCallback, useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { MatchConfig, MatchConfigID } from '@playq/services-rush';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

import { matchGetKeys } from './constants';

export const useMatchGet = (
  id?: MatchConfigID,
  options?: UseQueryOptions<MatchConfig | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => matchGetKeys.concat(id), [id]);
  const { mutate, ...res } = useEitherQuery(
    keys,
    () => {
      if (id) {
        return services2.matchesService.get(id);
      }
    },
    options
  );

  const mutateUpdatedMatch = useCallback(
    (match: MatchConfig) => {
      if (res.data) {
        mutate(match);
      }
    },
    [mutate, res.data]
  );

  return {
    ...res,
    tags: keys,
    mutate,
    mutateUpdatedMatch,
  };
};
