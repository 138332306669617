import { UseQueryOptions } from '@tanstack/react-query';
import { useMemo } from 'react';

import { GenericFailure, GenericFailureCode } from '@playq/services-shared';
import { AppID, Environment } from '@playq/octopus-common';

import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { ServicesOptions, ServiceConfig, getServiceMethods } from '/component/Services/Config/ServicesHelper';
import { services2 } from '/api/services2';

export const fingerprintServiceQueryKeys: unknown[] = [ServicesOptions.Fingerprint, 'service', 'get'];
export const sonarServiceQueryKeys: unknown[] = [ServicesOptions.Sonar, 'service', 'get'];
export const bookkeeperServiceQueryKeys: unknown[] = [ServicesOptions.Bookkeeper, 'service', 'get'];

export const useGetServicesConfig = (
  service: ServicesOptions,
  appID: AppID,
  env: Environment,
  options?: UseQueryOptions<ServiceConfig | undefined, GenericFailure | Error>
) => {
  const methods = getServiceMethods(services2, service);
  const defaultConfig = methods.createBlankConfig(env);

  const serviceQueryKeys = useMemo(() => [service, appID, env, 'service', 'get'], [service, appID, env]);

  const res = useEitherQuery<GenericFailure | Error, ServiceConfig | undefined>(
    [service, appID, env, 'service', 'get'],
    () => {
      if (methods.getClassName() === ServicesOptions.Fingerprint) {
        return methods.getConfig$(appID, env, true);
      }
      return methods.getConfig$(appID, env);
    },
    {
      onError: (err) => {
        if (err instanceof GenericFailure && err.code === GenericFailureCode.EntityNotFound) {
          console.error(err.message);
          return;
        }
        snackbarService.genericFailure(err);
      },
      initialData: defaultConfig,
      ...options,
    }
  );

  const serializedConfig = res.data?.serialize() || defaultConfig.serialize();

  const error = useMemo(() => {
    if (res.error instanceof GenericFailure && res.error.code === GenericFailureCode.EntityNotFound) {
      return;
    }
    return res.error;
  }, [res.error]);

  return {
    ...res,
    config: res.data || defaultConfig,
    serializedConfig,
    isValid: methods.validationSchema.isValidSync(serializedConfig),
    type: service,
    serviceQueryKeys,
    error,
  };
};
