import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { MarketInfo, MarketImagesResponse } from '@playq/octopus2-markets';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

const emptyArray: [] = [];

export const useRetrieveMarketsImage = (
  markets: MarketInfo[],
  options?: UseQueryOptions<MarketImagesResponse | undefined, GenericFailure | Error>
) => {
  const res = useEitherQuery(markets, () => services2.marketsService.retrieveMarketsImage(markets), {
    keepPreviousData: true,
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
  });

  return { ...res, images: res.data?.images || emptyArray };
};
