import { UseMutationOptions } from '@tanstack/react-query';

import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { snackbarService } from '/common/snackbarService';
import { useEitherMutation } from '/api/service-hooks';

import { DeleteReceivedMessagesPayload } from './types';

export const useDeleteAllReceivedMessages = (
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, DeleteReceivedMessagesPayload>
) =>
  useEitherMutation(
    ({ appID, messageReceiver, env }) =>
      services2.teamsChatLookupService.deleteAllReceivedMessages(appID, messageReceiver, env),
    {
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );
