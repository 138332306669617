import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { Filter } from '@playq/octopus-common';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { SpacesSort, QuerySpacesResponse, Space } from '@playq/octopus2-apps';

import { snackbarService } from '/common/snackbarService';
import {
  UseQueryOptionsExtended,
  useEitherQuery,
  useQueryPrefetch,
  useMutateQueryDataAfterEntityDeleting,
} from '/api/service-hooks';
import { iteratorMaxLimit } from '/constants';
import { services2 } from '/api/services2';

import { spacesServiceQueryKeys } from './constants';
import { createNewSpacesResponseAfterSpaceDeleting, mutateFeaturesAfterSpaceDeleting } from './helpers';

const emptyArray: [] = [];

const defaultQuery = {
  iterator: new OffsetLimit({ limit: iteratorMaxLimit, offset: 0 }),
  sortBy: [],
  filterBy: {},
};

export const useSpacesQuery = (
  iterator: OffsetLimit = defaultQuery.iterator,
  sortBy: SpacesSort[] = defaultQuery.sortBy,
  filterBy: { [key: string]: Filter } = defaultQuery.filterBy,
  options?: UseQueryOptionsExtended<QuerySpacesResponse | undefined, GenericFailure | Error>
) => {
  const queryClient = useQueryClient();

  const keys = useMemo(() => spacesServiceQueryKeys.concat(iterator, sortBy, filterBy), [iterator, sortBy, filterBy]);

  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => services2.spacesService.querySpaces(iterator, sortBy, filterBy),
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: options?.enablePrefetch,
    total,
    args: [iterator, sortBy, filterBy],
    serviceMethod: services2.spacesService.querySpaces.bind(services2.spacesService),
  });

  const mutateDeletedSpace = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator,
    mutate,
    refetch,
    getID: (s?: Space) => s?.id,
    getEntities: (r: QuerySpacesResponse) => r.entities,
    setEntities: (r: QuerySpacesResponse, newEntities: Space[]) => {
      r.entities = newEntities;
    },
    createNewResponse: createNewSpacesResponseAfterSpaceDeleting,
    mutateCustomFields: mutateFeaturesAfterSpaceDeleting,
  });

  const spaces = res.data ? res.data.entities : emptyArray;

  return {
    ...res,
    spaces,
    total,
    tags: keys,
    mutate,
    mutateDeletedSpace,
    refetch,
  };
};
