import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { MatchConfig } from '@playq/services-rush';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { updateMatchMutationKey } from './constants';

export const useMatchUpdate = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, MatchConfig, MatchConfig | undefined>
) =>
  useEitherMutation((data) => services2.matchesService.update(data), {
    mutationKey: updateMatchMutationKey,
    ...options,
  });
