import { QueryKey, MutationKey } from '@tanstack/react-query';

import { services2 } from '/api/services2';

export const problemsService = services2.synapseProblemsService;
export const problemsServiceName = problemsService.getClassName();

// the same names as the names of the methods in /common/scripts/definitions/script-hooks.d.ts
export const PROBLEM_PROMOTION_HOOK_NAME = 'onSynapseProblemPromote';
export const PROBLEM_DEMOTION_HOOK_NAME = 'onSynapseProblemDemote';
export const PROBLEM_SAVE_HOOK_NAME = 'onSynapseProblemSave';

export const problemsQueryKeys: QueryKey = ['problems', 'query'];
export const retrieveProblemQueryKey: QueryKey = ['problem', 'retrieve'];

export const createProblemMutationKey: MutationKey = ['problem', 'mutation', 'create'];
export const updateProblemMutationKey: MutationKey = ['problem', 'mutation', 'update'];
export const deleteProblemMutationKey: MutationKey = ['problem', 'mutation', 'delete'];
