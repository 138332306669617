import { UseMutationOptions } from '@tanstack/react-query';

import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { NotificationsResponse } from '@playq/octopus-notifications';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

export const useAddNotificationAnnotation = (
  options?: UseMutationOptions<
    CommonSuccess,
    GenericFailure | Error,
    { notificationID: number; message: string },
    NotificationsResponse | undefined
  >
) => {
  return useEitherMutation(
    ({ notificationID, message }) => services2.notificationsTimelineService.addAnnotation(notificationID, message),
    options
  );
};
