import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UseQueryOptions } from '@tanstack/react-query';

import { AppEntityContentResponse } from '@playq/octopus2-apps';
import { GenericFailure } from '@playq/services-shared';
import { Segmentation } from '@playq/octopus2-analytics';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { appToolkit } from '/store';

export const segmentRetrieveKeys: unknown[] = ['segment', 'retrieve'];

const emptyArray: [] = [];

export const useSegmentationRetrieve = (
  version?: number,
  options?: UseQueryOptions<AppEntityContentResponse | undefined, GenericFailure | Error>
) => {
  const appID = useSelector(appToolkit.selectors.appID);

  const res = useEitherQuery(
    segmentRetrieveKeys.concat(appID, version),
    () => {
      if (appID) {
        return services2.appSegmentsService.retrieve(appID, version);
      }
    },
    {
      keepPreviousData: true,
      ...options,
    }
  );

  const segmentation = useMemo(() => {
    if (res.data !== undefined) {
      try {
        return new Segmentation(JSON.parse(res.data.entity.content));
      } catch (e) {
        console.error(e);
        return undefined;
      }
    }
  }, [res.data]);

  return {
    segmentation,
    globals: segmentation?.globals || emptyArray,
    segments: segmentation?.segments || emptyArray,
    version: res.data?.entity.version,
    lastVersion: res.data?.lastVersion,
    ...res,
  };
};
