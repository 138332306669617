import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { SynapseProblemID, AppSynapseProblemResponse } from '@playq/octopus2-ml';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks/useEitherQuery';
import { snackbarService } from '/common/snackbarService';

import { retrieveProblemQueryKey } from './constants';

export const useProblemRetrieve = (
  id?: SynapseProblemID,
  version?: number,
  options?: UseQueryOptions<AppSynapseProblemResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => retrieveProblemQueryKey.concat(id, version), [id, version]);

  const res = useEitherQuery(keys, () => services2.synapseProblemsService.retrieve(id as SynapseProblemID, version), {
    onError: (err) => {
      snackbarService.genericFailure(err);
    },
    ...options,
    enabled: id !== undefined && options?.enabled !== false,
  });

  return {
    ...res,
    tags: keys,
  };
};
