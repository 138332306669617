import { AppTeamTemplateId, GenericFailure } from '@playq/services-shared';

import { UseMutationOptionsExtended } from '/api/service-hooks';
import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService';
import { onResponseError } from '../helpers';

import { createTeamMutationKey, teamsService, teamsServiceName } from './constants';
import { ICreateTeamParams, ITeamCreateOptionsVariables } from './types';

export const useTeamCreateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<AppTeamTemplateId, GenericFailure | Error, ITeamCreateOptionsVariables>
) =>
  useEitherMutationScripted<ICreateTeamParams, AppTeamTemplateId, IInvokeScriptsHook>({
    serviceMethod: teamsService.create.bind(teamsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: teamsServiceName,
      mutationType: 'team creation',
    },
    options: {
      onError: onResponseError,
      mutationKey: createTeamMutationKey,
      ...options,
    },
  });
