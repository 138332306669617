import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { FilesResponse } from '@playq/octopus2-devices';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { deviceToolkit } from '/store';

export const filesDevices: unknown[] = ['files', 'devices'];
export const useGetFiles = (
  path: string,
  options?: UseQueryOptions<FilesResponse | undefined, GenericFailure | Error>
) => {
  const subscribtionDevice = useSelector(deviceToolkit.selectors.subscribtionDevice);
  const { mutate, ...res } = useEitherQuery(
    filesDevices.concat(subscribtionDevice?.device.id, path),
    () => {
      if (subscribtionDevice) {
        return services2.onlineSubscriptionService.files(subscribtionDevice.device.id, path);
      }
    },
    {
      onError: (err) => snackbarService.genericFailure(err),
      staleTime: 0,
      cacheTime: 0,
      ...options,
    }
  );

  const directories = useMemo(() => res.data?.dirs || [], [res.data?.dirs]);
  const files = useMemo(() => res.data?.files || [], [res.data?.files]);
  return { ...res, directories, files };
};
