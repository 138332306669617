import { QueryKey, MutationKey } from '@tanstack/react-query';

import { services2 } from '/api/services2';

export const laddersService = services2.lboardsService;
export const laddersServiceName = laddersService.getClassName();

// the same names as the names of the methods in /common/scripts/definitions/script-hooks.d.ts
export const LADDER_SAVE_HOOK_NAME = 'onLeaderboardSave';
export const LADDER_PROMOTE_HOOK_NAME = 'onLeaderboardPromote';
export const LADDER_DEMOTE_HOOK_NAME = 'onLeaderboardDemote';

export const leaderboardGetKeys: QueryKey = ['leaderboard', 'retrieve'];
export const leaderboardsQueryKeys: QueryKey = ['leaderboards', 'query'];

export const createLeaderboardMutationKey: MutationKey = ['lboardsService', 'mutation', 'create'];
export const updateLeaderboardMutationKey: MutationKey = ['lboardsService', 'mutation', 'update'];
export const deleteLeaderboardMutationKey: MutationKey = ['lboardsService', 'mutation', 'delete'];
