import { MutationKey } from '@tanstack/react-query';

import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { AppID, Environment } from '@playq/octopus-common';
import { TeamIdWithTemplateId, TeamParameters } from '@playq/services-teams';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export type IUpdateTeamPayload = {
  appID: AppID;
  teamId: TeamIdWithTemplateId;
  teamParameters: TeamParameters;
  env: Environment;
};

export const updateTeamMutationKey: MutationKey = ['team', 'support', 'create'];

export const useUpdateTeam = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, IUpdateTeamPayload>
) =>
  useEitherMutation(
    ({ appID, teamId, teamParameters, env }) =>
      services2.teamsLookupService.updateTeam(appID, teamId, teamParameters, env),
    {
      onError: (err) => snackbarService.genericFailure(err),
      mutationKey: updateTeamMutationKey,
      ...options,
    }
  );
