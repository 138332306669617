import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { MatchConfigID } from '@playq/services-rush';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { deleteMatchMutationKey } from './constants';

export const useMatchDelete = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, MatchConfigID>
) =>
  useEitherMutation((id) => services2.matchesService.delete(id), {
    mutationKey: deleteMatchMutationKey,
    ...options,
  });
