import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { SynapseProblemCreateData, SynapseProblemID, AppSynapseProblemResponse } from '@playq/octopus2-ml';

import { UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';

import { IUpdateProblemParams, IProblemUpdateOptionsVariables } from './types';
import { problemsService, problemsServiceName, updateProblemMutationKey } from './constants';

export type ProblemUpdatePayload = {
  id: SynapseProblemID;
  data: SynapseProblemCreateData;
  version: number | undefined;
};

export const useProblemUpdateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    IProblemUpdateOptionsVariables,
    AppSynapseProblemResponse | undefined
  >
) =>
  useEitherMutationScripted<
    IUpdateProblemParams,
    CommonSuccess,
    IInvokeScriptsHook,
    AppSynapseProblemResponse | undefined
  >({
    serviceMethod: problemsService.update.bind(problemsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: problemsServiceName,
      mutationType: 'synapse problem update',
    },
    options: {
      mutationKey: updateProblemMutationKey,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    },
  });
