import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { EntityPromotionsResponse, PromotionFeatures } from '@playq/octopus-common';

import { IBasePromotionService } from '/api/hooks/promotions/types';
import { useEitherQuery } from '/api/service-hooks';

const queryPromotionsKeys: unknown[] = ['promotions', 'service', 'query'];
export const useQueryPromotions = <ID>(
  id: ID | undefined,
  features: PromotionFeatures[],
  queryPromotions?: IBasePromotionService<ID>['queryPromotions'],
  options?: UseQueryOptions<EntityPromotionsResponse | undefined, GenericFailure | Error>
) =>
  useEitherQuery(
    queryPromotionsKeys.concat(id, features),
    () => {
      if (id !== undefined) {
        return queryPromotions?.(id, features);
      }
    },
    {
      enabled: id !== undefined && !!queryPromotions,
      keepPreviousData: true,
      ...options,
    }
  );
