import { useCallback, useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { DevicesSubscriptions, DeviceID } from '@playq/octopus2-devices';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const subscribtionsDevices: unknown[] = ['subscribtions', 'devices'];
export const useGetSubscribtions = (
  options?: UseQueryOptions<DevicesSubscriptions | undefined, GenericFailure | Error>
) => {
  const { mutate, ...res } = useEitherQuery(
    subscribtionsDevices,
    () => {
      return services2.onlineSubscriptionService.subscriptions();
    },
    {
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );
  const addSubscriptionCache = useCallback(
    (deviceId: DeviceID) => {
      mutate((prevSubscriptions) => {
        if (!prevSubscriptions) {
          return;
        }
        const newResponse = new DevicesSubscriptions(prevSubscriptions.serialize());
        newResponse.devices = [...prevSubscriptions.devices, deviceId];
        return newResponse;
      });
    },
    [mutate]
  );
  const removeSubscriptionCache = useCallback(
    (deviceId: DeviceID) => {
      mutate((prevSubscriptions) => {
        if (!prevSubscriptions) {
          return;
        }
        const newResponse = new DevicesSubscriptions(prevSubscriptions.serialize());
        newResponse.devices = prevSubscriptions.devices.filter((item) => item.device !== deviceId.device);

        return newResponse;
      });
    },
    [mutate]
  );

  const deviceSubscribtions = useMemo(() => res.data?.devices || [], [res.data]);
  return { ...res, deviceSubscribtions, addSubscriptionCache, removeSubscriptionCache };
};
