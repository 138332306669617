import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UseQueryOptions } from '@tanstack/react-query';

import { Filter } from '@playq/octopus-common';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import {
  DevicesSort,
  QueryDevicesResponse,
  DeviceID,
  UnknownDevice,
  KnownDevice,
  Device,
  AppDevice,
} from '@playq/octopus2-devices';

import { services2 } from '/api/services2';
import { useEitherQuery, useQueryPrefetch } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { storage } from '/storage';
import { deviceToolkit } from '/store';

export const onlineDevicesManagement: unknown[] = ['online', 'devices'];

export const useGetDevices = (
  company: number,
  iterator: OffsetLimit,
  sortBy: DevicesSort[] = [],
  filterBy: {
    [key: string]: Filter;
  } = {},
  options?: UseQueryOptions<QueryDevicesResponse | undefined, GenericFailure | Error>
) => {
  const onlineDeviceKeys = onlineDevicesManagement.concat(company, iterator, filterBy, sortBy);
  const sub = useSelector(deviceToolkit.selectors.subscribtionDevice);
  const { mutate, ...res } = useEitherQuery(
    onlineDeviceKeys,
    () => {
      return services2.onlineDeviceService.queryDevices(company, iterator, sortBy, filterBy);
    },
    {
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );
  const enablePrefetch = useMemo(() => {
    return options?.enabled ?? true;
  }, [options?.enabled]);
  useQueryPrefetch({
    keys: onlineDeviceKeys,
    enabled: enablePrefetch,
    total: res.data?.total ?? 0,
    args: [company, iterator, sortBy, filterBy],
    serviceMethod: services2.onlineDeviceService.queryDevices.bind(services2.audienceSupportService),
  });

  const mutateRemoveDeviceCache = useCallback(
    (deviceId: DeviceID) => {
      mutate((prevDeviceInfo) => {
        if (!prevDeviceInfo) {
          return;
        }
        const newResponse = new QueryDevicesResponse(prevDeviceInfo.serialize());
        newResponse.devices = prevDeviceInfo.devices.map((device) => {
          if (device.device.id === deviceId) {
            const appDevice = new AppDevice(device.serialize());
            appDevice.device = new UnknownDevice(device.device.serialize());
            if (sub?.device.serialize().id === deviceId.serialize()) {
              storage.set(
                'DeviceID',
                JSON.stringify({ deviceID: device.device.id.serialize(), device: appDevice.device.serialize() })
              );
            }
            return appDevice;
          }
          return device;
        });

        return newResponse;
      });
    },
    [mutate, sub?.device]
  );
  const mutateUpdateDeviceCache = useCallback(
    (deviceId: DeviceID, name: string) => {
      mutate((prevDeviceInfo) => {
        if (!prevDeviceInfo) {
          return;
        }
        const newResponse = new QueryDevicesResponse(prevDeviceInfo.serialize());
        newResponse.devices = prevDeviceInfo.devices.map((device) => {
          if (device.device.id === deviceId) {
            const appDevice = new AppDevice(device.serialize());
            appDevice.device = new KnownDevice({ ...device.device.serialize(), name: name });
            if (sub?.device.serialize().id === deviceId.serialize()) {
              storage.set(
                'DeviceID',
                JSON.stringify({ deviceID: device.device.id.serialize(), device: appDevice.device.serialize() })
              );
            }
            return appDevice;
          }
          return device;
        });
        return newResponse;
      });
    },
    [mutate, sub?.device]
  );
  const mutateOnlineDevices = useCallback(
    (currentDevice: Device) => {
      mutate((prevDeviceInfo) => {
        if (!prevDeviceInfo) {
          return;
        }
        const newResponse = new QueryDevicesResponse(prevDeviceInfo.serialize());
        newResponse.devices = prevDeviceInfo.devices.map((device) => {
          if (device.device.id.serialize() === currentDevice.id.serialize()) {
            const appDevice = new AppDevice(device.serialize());
            if (device.device instanceof KnownDevice) {
              const knownDevice = new KnownDevice(device.device.serialize());
              knownDevice.connection = currentDevice.connection;
              appDevice.device = currentDevice;
              return appDevice;
            } else {
              const unknownDevice = new UnknownDevice(device.device.serialize());
              unknownDevice.connection = currentDevice.connection;
              appDevice.device = currentDevice;
              return appDevice;
            }
          }
          return device;
        });

        return newResponse;
      });
    },
    [mutate]
  );

  const devices = useMemo(() => res.data?.devices || [], [res.data]);
  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);
  return { ...res, devices, total, mutateRemoveDeviceCache, mutateUpdateDeviceCache, mutateOnlineDevices };
};
