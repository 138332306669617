import { UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { AppID, Environment } from '@playq/octopus-common';
import { GenericFailure } from '@playq/services-shared';
import { TeamIdWithTemplateId, TeamLookup } from '@playq/services-teams';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';

const keys: unknown[] = ['teams', 'service', 'support', 'getTeam'];
const emptyArray: [] = [];

export const useGetTeam = (
  teamId: TeamIdWithTemplateId,
  env: Environment,
  options?: UseQueryOptions<TeamLookup | undefined, GenericFailure | Error>
) => {
  const appID: AppID | undefined = useSelector(appToolkit.selectors.appID);

  const res = useEitherQuery(
    keys.concat(appID, teamId, env),
    () => {
      if (appID) {
        return services2.teamsLookupService.getTeam(appID, teamId, env);
      }
    },
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  return {
    ...res,
    vault: res.data?.vault,
    team: res.data?.team,
    members: res.data?.members || emptyArray,
  };
};
