import { GenericFailure } from '@playq/services-shared';
import { QueryScriptResponse, ScriptID } from '@playq/octopus2-scripts';

import { filterById } from '/helpers';
import { MutateCustomFieldsArgs } from '/api/service-hooks';

import { INERRUPTED_BY_SCRIPS_DIAGNOSTICS_MESSAGE } from './constants';

export const isMutationInterruptedByScripts = (error: Error | GenericFailure) =>
  error instanceof GenericFailure && error.diagnostics === INERRUPTED_BY_SCRIPS_DIAGNOSTICS_MESSAGE;

export const createNewScriptsResponseAfterScriptDeleting = (
  prevResponse: QueryScriptResponse,
  removedEntityID?: ScriptID | ScriptID[]
) => {
  const newResponse = new QueryScriptResponse(prevResponse.serialize());
  if (removedEntityID !== undefined) {
    newResponse.activations = prevResponse.activations.filter(filterById(removedEntityID));
    newResponse.features = prevResponse.features.filter(filterById(removedEntityID));
  }

  return newResponse;
};

export const mutateFeaturesAndActivationsAfterScriptDeleting = ({
  response,
  nextResponse,
  id,
}: MutateCustomFieldsArgs<QueryScriptResponse, ScriptID>) => {
  if (!id || !nextResponse) {
    return;
  }
  const nextScriptFeatures = nextResponse.features.find(filterById(id, true));
  if (nextScriptFeatures !== undefined) {
    response.features.push(nextScriptFeatures);
  }
  const nextScriptActivations = nextResponse.activations.find(filterById(id, true));
  if (nextScriptActivations !== undefined) {
    response.activations.push(nextScriptActivations);
  }
};
