import { useCallback, useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { AppLadderId, LadderTemplate } from '@playq/services-clerk';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

import { UpdateLeaderboardPayload } from './types';
import { leaderboardGetKeys } from './constants';

export const useLeaderboardGet = (
  id?: AppLadderId,
  options?: UseQueryOptions<LadderTemplate | undefined, GenericFailure | Error>
) => {
  const isValid = useMemo(() => id?.appId !== undefined && id?.ladderId !== undefined, [id]);
  const enabled = useMemo(() => options?.enabled !== false && isValid, [options?.enabled, isValid]);

  const keys = useMemo(() => leaderboardGetKeys.concat(id), [id]);

  const { mutate, ...res } = useEitherQuery(keys, () => services2.lboardsService.getLadder(id as AppLadderId), {
    ...options,
    enabled,
  });

  const mutateUpdatedLeaderboard = useCallback(
    (payload: UpdateLeaderboardPayload) => {
      if (res.data) {
        mutate(
          new LadderTemplate({
            id: res.data.id.serialize(),
            ...payload.data.serialize(),
          })
        );
      }
    },
    [mutate, res.data]
  );

  return {
    ...res,
    tags: keys,
    mutate,
    mutateUpdatedLeaderboard,
  };
};
