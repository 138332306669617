import { useCallback, useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';
import _ from 'lodash';

import { AppTeamTemplateId, GenericFailure } from '@playq/services-shared';
import { TeamTemplate } from '@playq/services-teams';
import { TeamTemplateResponse } from '@playq/octopus2-social';
import { ItemDetails } from '@playq/octopus2-economy';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

import { IUpdateTeamPayload } from './types';
import { teamGetKeys } from './constants';

export const useTeamGet = (
  id?: AppTeamTemplateId,
  options?: UseQueryOptions<TeamTemplateResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => teamGetKeys.concat(id), [id]);

  const { mutate, ...res } = useEitherQuery(keys, () => services2.teamsService.get(id as AppTeamTemplateId), {
    ...options,
    enabled: options?.enabled !== false && id !== undefined,
  });

  const mutateUpdatedTeam = useCallback(
    (payload: IUpdateTeamPayload) => {
      if (res.data) {
        const response = new TeamTemplateResponse();
        response.gameCurrency = _.mapValues(payload.gameCurrency, (val) => new ItemDetails(val));
        response.team = new TeamTemplate({
          id: payload.id.serialize(),
          ...payload.data.serialize(),
        });
        mutate(response);
      }
    },
    [mutate, res.data]
  );

  return {
    ...res,
    tags: keys,
    mutate,
    mutateUpdatedTeam,
  };
};
