import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { TeamTemplateResponse } from '@playq/octopus2-social';

import { UseMutationOptionsExtended } from '/api/service-hooks';
import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { teamsService, teamsServiceName, updateTeamMutationKey } from './constants';
import { IUpdateTeamParams, ITeamUpdateOptionsVariables } from './types';

export const useTeamUpdateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    ITeamUpdateOptionsVariables,
    TeamTemplateResponse | undefined
  >
) =>
  useEitherMutationScripted<IUpdateTeamParams, CommonSuccess, IInvokeScriptsHook, TeamTemplateResponse | undefined>({
    serviceMethod: teamsService.update.bind(teamsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: teamsServiceName,
      mutationType: 'team update',
    },
    options: {
      onError: onResponseError,
      mutationKey: updateTeamMutationKey,
      ...options,
    },
  });
