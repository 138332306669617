import { useCallback, useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { Script, ScriptResponse, ScriptCreateData, ScriptID } from '@playq/octopus2-scripts';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

import { scriptsRetrieveKeys } from './constants';

export const useScriptsRetrieve = (
  id?: ScriptID,
  version?: number,
  options?: UseQueryOptions<ScriptResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => scriptsRetrieveKeys.concat(id, version), [id, version]);

  const { mutate, ...res } = useEitherQuery(
    keys,
    () => {
      return services2.scriptsService.retrieve(id as ScriptID, version);
    },
    { ...options, enabled: options?.enabled !== false && id !== undefined }
  );

  const mutateUpdatedItem = useCallback(
    (updatedData: ScriptCreateData) => {
      if (res.data) {
        const cloned = new ScriptResponse(res.data.serialize());
        cloned.lastVersion += 1;
        cloned.script = new Script(res.data.script.serialize());
        cloned.script.loadScriptDataSerialized({
          ...updatedData.serialize(),
        });
        mutate(cloned);
      }
    },
    [mutate, res.data]
  );

  return {
    ...res,
    tags: keys,
    mutate,
    mutateUpdatedItem,
  };
};
