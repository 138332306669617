import { UseMutationOptions } from '@tanstack/react-query';

import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { UserAnnotations } from '@playq/octopus-notifications';

import { useEitherMutation } from '/api/service-hooks';
import type { TimelineService } from '/common/AppTimeline/TimelineModules/types';

export const useAddEntityAnnotations = (
  addAnnotation: NonNullable<TimelineService['addAnnotation']>,
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, string, UserAnnotations | undefined>
) => useEitherMutation((message) => addAnnotation(message), options);
