import { MutationKey } from '@tanstack/query-core';

import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { SpaceID, AppID } from '@playq/octopus-common';

import { UseMutationOptionsExtended, useEitherMutation } from '/api/service-hooks';
import { services2, experimentsQueryKeys, flowQueryKeys, gameEventsQueryKeys } from '/api';
import { snackbarService } from '/common/snackbarService';

import { spacePromotionsCleanKey } from './constants';

export type UseAppSpacePromotionsCleanParams = {
  appID: AppID;
  spaceID: SpaceID;
};

export const useAppSpacePromotionsClean = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, UseAppSpacePromotionsCleanParams>
) => {
  return useEitherMutation(({ appID, spaceID }) => services2.spacesPromotionsService.cleanPromotions(appID, spaceID), {
    onError: (err) => {
      snackbarService.genericFailure(err);
    },
    removeQueriesKeysArray: [experimentsQueryKeys, flowQueryKeys, gameEventsQueryKeys],
    mutationKey: spacePromotionsCleanKey as MutationKey,
    ...options,
  });
};
