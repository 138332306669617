import { AppLadderId } from '@playq/services-clerk';
import { LaddersResponse } from '@playq/octopus2-social';

import { MutateCustomFieldsArgs } from '/api/service-hooks';

const removeItemFromPromotionIfPromoted = (id: AppLadderId, promotions: AppLadderId[]): boolean => {
  const removedLeaderboardID = promotions.splice(
    promotions.findIndex((teamID) => teamID.serialize() === id.serialize()),
    1
  );
  return removedLeaderboardID.length > 0;
};

export const mutateEnvsAfterLboardDeleting = ({
  response,
  nextResponse,
  id,
}: MutateCustomFieldsArgs<LaddersResponse, AppLadderId>) => {
  if (!id || !nextResponse) {
    return;
  }
  if (removeItemFromPromotionIfPromoted(id, nextResponse.envs.sandbox)) {
    response.envs.sandbox.push(id);
  }
  if (removeItemFromPromotionIfPromoted(id, nextResponse.envs.live)) {
    response.envs.live.push(id);
  }
};
