import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { SynapseProblemID } from '@playq/octopus2-ml';
import { SynapseProblemSchema } from '@playq/services-synapse';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const problemSchemaKeys: unknown[] = ['problem', 'schema'];

export const useProblemSchema = (
  id: SynapseProblemID,
  version?: number,
  options?: UseQueryOptions<SynapseProblemSchema | undefined, GenericFailure | Error>
) =>
  useEitherQuery(
    problemSchemaKeys.concat(id, version),
    () => {
      if (version !== undefined) {
        return services2.synapseProblemsService.problemSchema(id, version);
      }
    },
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );
