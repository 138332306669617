import { GenericFailure } from '@playq/services-shared';
import { AppSynapseProblem } from '@playq/octopus2-ml';

import { UseMutationOptionsExtended } from '/api/service-hooks';
import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { createProblemMutationKey, problemsService, problemsServiceName } from './constants';
import { ICreateProblemParams, IProblemCreateOptionsVariables } from './types';

export const useProblemCreateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<AppSynapseProblem, GenericFailure | Error, IProblemCreateOptionsVariables>
) =>
  useEitherMutationScripted<ICreateProblemParams, AppSynapseProblem, IInvokeScriptsHook>({
    serviceMethod: problemsService.create.bind(problemsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: problemsServiceName,
      mutationType: 'synapse problem creation',
    },
    options: {
      mutationKey: createProblemMutationKey,
      onError: onResponseError,
      ...options,
    },
  });
